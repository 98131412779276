import React from 'react'
import "./dashboard.css"
import Footer from './Footer'
import { InputGroup,Form } from 'react-bootstrap'
import 'boxicons'
const Dashboard = () => {
  return (
    <>
    <section id="dashboard">
      <div id="search">
      <InputGroup className="">
        <InputGroup.Text id="basic-addon1">🔎</InputGroup.Text>
        <Form.Control
          placeholder="Search Departments , articles etc."
          aria-label="Username"
          aria-describedby="basic-addon1"
        />
      </InputGroup>
<div>
  <box-icon name='cog'></box-icon>
</div>
      <div>
        <box-icon name='bell' ></box-icon>
      </div>
      </div>
      <div id="get-started">
        <h1>Get started with Ikshana</h1>
        <div id="split">
        <p>
Use the chatbot option to voice your concerns , Get to know about your departments in the departments section , Read about recent developments , Voice your Concerns one step at a time </p>
<img src="//unsplash.it/500/500" />
        </div>
      </div>
      <div id="browse-departments">
        <h1>Browse Departments</h1>
        <div id='browse-scroll'>

          <div id="department-card">
          <img src={process.env.PUBLIC_URL + "/indiaLogo.png"} />
          <h1 id="department-h1">Department of Health and family welfare</h1>
          </div>
          <div id="department-card">
          <img src={process.env.PUBLIC_URL + "/indiaLogo.png"} />
          <h1 id="department-h1">Department of Health and family welfare</h1>
          </div>
          <div id="department-card">
          <img src={process.env.PUBLIC_URL + "/indiaLogo.png"} />
          <h1 id="department-h1">Department of Health and family welfare</h1>
          </div>
          <div id="department-card">
          <img src={process.env.PUBLIC_URL + "/indiaLogo.png"} />
          <h1 id="department-h1">Department of Health and family welfare</h1>
          </div>
          <div id="department-card">
          <img src={process.env.PUBLIC_URL + "/indiaLogo.png"} />
          <h1 id="department-h1">Department of Health and family welfare</h1>
          </div>
        </div>
      </div>
      <div id="recent-developments">
        <h1>Recent developments</h1>
        <div id='browse-scroll-recent'>
          <div id="recent-card">
            <p>Governors must assent or send back bills as soon as possible: Supreme Court
DHANANJAY MAHAPATRA / TNN / Apr 25, 2023, 02:12 IST
</p>
<img src="//unsplash.it/500/500" />
          </div>
          <div id="recent-card"></div>
          <div id="recent-card"></div>
          <div id="recent-card"></div>
        </div>
      </div>
      <div id="categories">
        <h1>Categories</h1>
        <div id="categories-scroll">
        <div id="categories-scroll-grid">
          <div id="category-card">
          <img src={process.env.PUBLIC_URL + "/indiaLogo.png"} />
            <h1 id="category-h1">Health and family concerns</h1>
          </div>
          <div id="category-card">
          <img src={process.env.PUBLIC_URL + "/indiaLogo.png"} />
            <h1 id="category-h1">Education</h1>
          </div>
          <div id="category-card">
          <img src={process.env.PUBLIC_URL + "/indiaLogo.png"} />
            <h1 id="category-h1">Highways and road authorities</h1>
          </div>
          <div id="category-card">
          <img src={process.env.PUBLIC_URL + "/indiaLogo.png"} />
            <h1 id="category-h1">Women and child development</h1>
          </div>
          <div id="category-card">
          <img src={process.env.PUBLIC_URL + "/indiaLogo.png"} />
            <h1 id="category-h1">Income tax</h1>
          </div>
          <div id="category-card">
          <img src={process.env.PUBLIC_URL + "/indiaLogo.png"} />
            <h1 id="category-h1">Law</h1>
          </div>
          <div id="category-card">
          <img src={process.env.PUBLIC_URL + "/indiaLogo.png"} />
            <h1 id="category-h1">Power department</h1>
          </div>
          <div id="category-card">
          <img src={process.env.PUBLIC_URL + "/indiaLogo.png"} />
            <h1 id="category-h1">Environment</h1>
          </div>
          <div id="category-card">
          <img src={process.env.PUBLIC_URL + "/indiaLogo.png"} />
            <h1 id="category-h1">Communications and IT</h1>
          </div>
          </div>
        </div>
      </div>
      <div id="share-app">
        <h1 id="share-h1">Share the Sankalp app</h1>
        <div id="split-share-app">
<img src="//unsplash.it/500/500" />
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam .</p>
</div>
      </div>
      
    </section>
    </>
  )
}

export default Dashboard