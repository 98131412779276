import logo from './logo.svg';
import './App.css';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Departments from './pages/Departments';
import Login from './pages/Login';
import RegisterConcern from './pages/RegisterConcern';
import Signup from './pages/Signup';
import YourConcerns from './pages/YourConcerns';
import Layout from './pages/Layout';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route index element={<Login />} />
          <Route path="signup" element={<Signup />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="yourConcerns" element={<YourConcerns />} />
          <Route path="departments" element={<Departments />} />
          <Route path="registerConcern" element={<RegisterConcern />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
