import React from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import "./login.css"
import { Link } from 'react-router-dom';
const Login = () => {
  return (
    <section>
      <div id='logo'>
        <img src={process.env.PUBLIC_URL + "/logo192.png"} />
        <h1 id='h1'>Ikshana - Voice and enhance</h1>
        <h3 id='h3'>LogIn</h3>
      </div>
    <Form>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Control type="email" placeholder="Email Address or Phone number" className='p-3'/>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Control type="password" placeholder="Password" className='p-3'/>
        
      </Form.Group>
      <div id="forgot-password">forgot password?</div>
        <Link to="/dashboard">
      <Button variant="primary" type="submit" id="login-button">
        Log In
      </Button>
        </Link>
    </Form>
    </section>
  )
}

export default Login;